/* font-size: calc([minimum size] + ([maximum size] - [minimum size]) * ((100vw - [minimum viewport width]) / ([maximum viewport width] - [minimum viewport width]))); */
@font-face {
  font-family: 'Wexville';
  src: url('webFonts/wexville/Wexville.ttf') format('truetype');
}
@font-face {
  font-family: 'Krylon';
  src: url('webFonts/krylon/KRYLON//Krylon-Regular.otf') format('opentype');
}
html, body {
  min-width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
  background-color: rgb(0, 0, 0);
  color: rgb(255, 250, 245) !important;
  font-family: 'Krylon', serif;
  letter-spacing: 1px !important;
  line-height: 1rem;
  -webkit-font-smoothing: antialiased;
  font-size: calc(20px + (30 - 20) * ((100vw - 360px) / (2048 - 360)));
  scroll-behavior: auto;
}
h1, h2, h4, h5 {
  font-family: Wexville !important;
  font-size: calc(60px + (80 - 60) * ((100vw - 360px) / (2048 - 360)));
}

h3 {
  font-family: Wexville !important;
  font-size: calc(60px + (80 - 60) * ((100vw - 360px) / (2048 - 360)));
}

.small {
  font-size: calc(18px + (24 - 18) * ((100vw - 360px) / (2048 - 360)));
}

.coffee {
  text-align: center;
  width: fit-content;
  margin: auto;
  cursor:pointer
}

.coffee:hover {
  outline: 3px solid #ff972e;
  outline-offset: 10px;
  border-radius: 20px;
}

body {
  margin: 0;
}

.modal-content {
  border: none !important;
  border-radius: 0 !important;
}

strong {
  color: #FFC837;
}

.underline {
  background-image: linear-gradient(#ff3314 0 0);
  background-position: 0 80%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.underlineWhite {
  background-image: linear-gradient(to right, White, White);
  background-position: 0 80%; /*OR bottom left*/
  background-size: 100% 2px;
  background-repeat: no-repeat;
}

.underline:hover {
  background-position: 0 80%; /*OR bottom right*/
  background-size: 100% 2px;
}

.titleContainer {
  background-image: url(src/assets/TitleBackGroundAlpha.webp);
  background-color:transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20vh;
  display: table-cell;
  vertical-align: middle;
  width: 100vw;
  text-align: center;
}

.titleContainer2 {
  background-image: url(src/assets/title_background_big.webp);
  background-color:transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: auto;
  height: 100px;
  text-align: center;
  display: flex;
  align-self: center;
}

.titleContainer2 h1 {
  margin:auto;
  line-height:51px;
  vertical-align:middle;
}


rect {
  stroke: #C4BD83;
  stroke-width: 2;
  fill-opacity: 0.0;
  stroke-opacity: 1;
}
path {
  stroke: #C4BD83;
  stroke-width: 2;
  fill-opacity: 0.0;
  stroke-opacity: 1;
}
#Rectangle_60 {
  fill: none;
  stroke: #ff3314;
  stroke-miterlimit: 10;
  stroke-width: 2;
}
#Rectangle_60_fill {
  stroke: #C4BD83;
  stroke-miterlimit: 10;
  stroke-width: 1;
  fill: #ff3314;
  fill-opacity: 1;
}
:root {
  --bs-body-color: #212529 !important;
  --custom-grey: #313131;
  --custom-grey2: #2D2D2D;
  --custom-or: #C4BD83;
  --custom-orange: #FF3314;
}
.Op0 {
  opacity: 0;
}
.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: white;
  text-shadow: 0 1px 0 #fff;
  opacity: .5
}
.close:hover {
  color: #000;
  text-decoration: none
}
.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}
a.close.disabled {
  pointer-events: none
}
/*
 * fade in animation
 */
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/*scrollbar*/

@media only screen and (max-width: 479px) {
  html::-webkit-scrollbar {
    display: none;
  }
}
@media only screen and (min-width: 480px) {
  html {
  overflow: overlay;
  }
  html::-webkit-scrollbar {
  display: none;
  overflow: auto;
  }
  *::-webkit-scrollbar {
  width: 16px;
  }
  *::-webkit-scrollbar-track {
  border-radius: 8px;
  }
  *::-webkit-scrollbar-thumb {
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: #888;
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}
